.App {
  text-align: center;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.btn-submit {
  color: #fff;
  background-color: #00D19F;
  background-color: cutom-color;
  border-color: #00D19F;
  border-color: cutom-cor;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.btn-primary-white-text {
  color: #fff !important;
}

.a-breadcrumb {
  color: #000 !important;
  text-decoration: none !important;
}

.a-breadcrumb>a {
  color: #000 !important;
  text-decoration: none !important;
}

.btn-primary-reauth {
  padding: 20px !important
}

.top-to-bottom {
  animation: topToBottom 0.5s 0s forwards;
  margin-top: -24px;
}

@keyframes topToBottom {
  from {
    margin-top: -24px;
  }

  to {
    margin-top: 0px;
  }
}

.anima {
  animation: fadeinLoad 0.5s 0s forwards;
  /* background-color: #0054E6; */
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 100px;
  opacity: 0;
  width: auto;

}

@keyframes fadeinLoad {
  from {
    opacity: 0;
    padding: 0;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 0px;
    padding-right: 0px;
    border-radius: 0;
    width: 0;
    /* background-color: white; */
  }

  to {
    opacity: 1;
    border-radius: 100px;
    padding-left: 16px;
    padding-right: 26px;
    padding-top: 12px;
    padding-bottom: 12px;
    margin: 10px;
    width: auto;
    /* background-color: white; */
  }
}


.anima-icon {
  animation: translateIcon 0.5s 0s forwards;
  opacity: 0;
  margin-left: -56px;
}

@keyframes translateIcon {
  from {
    opacity: 0;
    margin-left: -56px;
  }

  to {
    opacity: 1;
    margin-left: 0px;
  }
}

.anima-text {
  animation: translateText 0.5s 0s forwards;
  opacity: 0;
  margin-left: -36px;
}

@keyframes translateText {
  from {
    opacity: 0;
    margin-left: -36px;
  }

  to {
    opacity: 1;
    margin-left: 2px;
  }
}

.react-multi-carousel-dot-list {
position: fixed !important;
bottom: 24px !important}

.flex-gap{
    column-gap: 0.625rem;
  }

  .font-header{
    font-size: 1.125rem
  }
@media (max-height: 620px ), (max-width: 320px) {
  .flex-gap{
    column-gap: -0.375rem;
  }

  .font-size{
    font-size: small;
  }

  .font-header{
    font-size: medium
  }
}