.menu-bar {
  /* background-color: #f5f5f5; */
  padding: 10px;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.menu-list {
  display: flex;
  justify-content: space-around;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.menu-item {
  flex: 1;
  text-align: center;
}

.menu-item a {
  display: block;
  padding: 10px;
  text-decoration: none;
  color: #333;
  font-weight: bold;
}

.menu-item a:hover {
  background-color: #f72a2a;
}

@media (max-height: 620px), (max-width: 320px) {
  .bar .menus {
    max-height: 35px;
  }

  .img .img-active {
    width: 20px;
    margin-bottom: -10px;
  }

  .img .img-inactive {
    width: 20px;
  }

  .img .img-text {
    font-size: 10px;
  }
}