/* .qr-scanner-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.qr-scanner-video {
  width: 100%;
  height: 100%;
  border-radius: 12px;
} */

.qr-scanner-container {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  /* background-color: white; */
  overflow: hidden;
  margin-top: 50px;
}

.qr-scanner-video {
  width: 90%;
  height: 500px;
  object-fit: cover;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 12px;
}

@media (max-height: 740px), (max-width: 375px) {
.qr-scanner-video {
  width: 90%;
  height: 400px;
  object-fit: cover;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 12px;
}
}
/* Gaya khusus ketika dalam mode landscape */
/* @media screen and (orientation: landscape) {
  .qr-scanner-container {
    justify-content: flex-start;
  }

  .qr-scanner-video {
    height: 100vh;
    max-width: 100%;
    object-fit: contain;
  }
} */