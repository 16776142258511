.font-label{
    font-size: 0.75rem/* 12px */;
    line-height: 1rem/* 16px */;
}

.image-menu{
        height: 3.5rem;
    }

.image-profile{
        height: 5rem;
        width: 5rem;
    }

    .icons{
        height: 3.5rem;
    }

    .content-width{
        width: 100%
    }
@media (max-height: 620px), (max-width: 320px) {
    .image-menu{
        height: 50px;
    }

    .font-label{
        font-size: 10px;
    }

    .image-profile{
        height: 4rem;
        width: 4rem;
    }

    .icons{
        height: 3rem;
    }

    .content-width{
        width: 17rem
    }
}