/* .qr-scanner-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.qr-scanner-video {
  width: 100%;
  height: 100%;
  border-radius: 12px;
} */

.camera-container {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  /* background-color: white; */
  overflow: hidden;
  margin-top: 50px;
}

.camera-video {
  width: 350px;
  height: 500px;
  overflow: hidden;
  position: relative;
  border: 2px solid #000;
  border-radius: 8px;
}

@media (max-height: 740px),
(max-width: 375px) {
  .camera-video {
    width: 350px;
    height: 450px;
    overflow: hidden;
    position: relative;
    border: 2px solid #000;
    border-radius: 8px;
  }
}

/* Gaya khusus ketika dalam mode landscape */
/* @media screen and (orientation: landscape) {
  .qr-scanner-container {
    justify-content: flex-start;
  }

  .qr-scanner-video {
    height: 100vh;
    max-width: 100%;
    object-fit: contain;
  }
} */