@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.Toastify__toast {
    border-radius: 12px;
}

.Toastify__toast-container {
padding: 15px;
}