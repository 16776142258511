.padding-form {
padding-top: 0.5rem;
padding-bottom: 0.5rem;
}

.img-size {
width: 9rem;
height: 9rem
}

.button-padding {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

@media (max-height: 620px), (max-width: 320px) {

  .padding-form {
    padding-top: 0;
    padding-bottom: 0;
  }

  .img-size {
    width: 6rem;
    height: 6rem;
  }

  .button-padding {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}